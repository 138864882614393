$small-desktop-width: 1200px;
$phone-width: 424px;
$tablet-width: 1100px;
$color : #fabe7b;



@mixin desktopOnly {
  @media (min-width: #{$small-desktop-width}) {
    @content;
  }
}
@mixin smallDesktop {
  @media (max-width: #{$small-desktop-width}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}



header{
  position: fixed;
  height: auto;
  left: 50%;
  top: 0px;
  transform: translate(-50%, 0);
  border-bottom: $color 1px solid;
  z-index: 99;
  width: 100%;
  max-width: 100%;
  box-shadow: 0px 5px 15px -8px rgba(0,0,0,0.7);
  background-color: white;
  &.top{
    .first-row{
      .container{
        width: 95%;
        max-width: 95%;
      }
    }
  }
  .first-row{
    .container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-block: 8px;
      transition: 300ms ease-in-out;
    }
    background-color: #fff;
    position: relative;
    z-index: 15;
  }
  &.open{
    border-bottom: $color 1px solid;
    .addons_info{
      top:0;
      opacity: 0;
    }
    .st0{fill:#232323;}
    .btn_burger span{
      background-color: $color;
    }
  }
  .addons_info{
    z-index: 1;
    position: absolute;
    background-color: #696969;
    border-bottom: 1px solid black;
    box-shadow: 0px 5px 15px -8px rgba(0,0,0,0.7);
    width: 100%;
    height: auto;
    top: 100%;
    left: 0;
    transition: 375ms ease-in-out;
    .container{
      display: flex;
      justify-content: flex-start;
      padding-block: 15px;
      width: 95%;
      max-width: 95%;
      p{
        margin-right: 10px;
      }
    }
    &.not_top{
      top:0;
      opacity: 0;
    }
  }
}


.nav_desktop{
  li{
    font-weight: bold;
    position: relative;
    font-size: larger;
    a{
      transition: 300ms ease-in-out;
    }
    &:after{
      content: "";
      position: absolute;
      left: 0;
      bottom: 100%;
      transform: translateY(20px);
      height: 0px;
      width: 0px;
      background-color: $color;
      z-index: 5;
      transition: 150ms ease-in-out;
    }
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 0px;
      background-color: $color;
      width: 0px;
      z-index: 5;
      transition: 150ms ease-in-out 150ms;
    }
    &:hover{
      &:after{

        height: 20px;
        width: 2px;
      }
      &:before{
        height: 2px;
        width: 30px;
      }
      a{
        color : $color;
      }
    }
  }
}

.link_home{
  max-width: 80px;
  #b{
    transition: 250ms ease-in;
    transition-delay: 250ms;

  }
  #a{
    transition: 250ms ease-in-out;

  }
  &:hover{
    #a{
      transform: translateX(43px);
    }
    #b{
      transform: translateX(-45px);
    }
  }
}

img{
  width: 100%;
  height: auto;
}

.nav_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #474347;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 100%;
  opacity: 0;
  z-index: 10;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      margin-bottom: 8px;

      a {
        text-decoration: none;
        color: aliceblue;
        font-size: 2em;
      }
    }

  }
  a {
    color: aliceblue;
    font-size: 2em;
    //text-decoration: none;
    .arrow {
      border: solid white;
      border-width: 0 6px 6px 0;
      display: inline-block;
      padding: 3px;
      margin-bottom: 3px;
      margin-right: 5px;
    }

    .right {
      transform: rotate(-45deg);
    }
  }
}

@keyframes menuOpen {
  0%{
    left: 100%;
    opacity: 0;
  }
  100%{
    left: 0%;
    opacity: 1;
  }
}
@keyframes menuClose {
  0%{
    left: 0%;
    opacity: 1;
  }
  100%{
    left: 100%;
    opacity: 0;
  }
}


.nav_container.open{
  left: 0;
  opacity: 1;
  animation: menuOpen 150ms ease-in-out;
}
.nav_container.close{
  left: 100%;
  opacity: 0;
  animation: menuClose 150ms ease-in-out;
}
.btn_burger{
  width: 40px;
  height: 28px;
  position: relative;
  cursor: pointer;
  padding: 5px;
  box-sizing: border-box;
  span{
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: $color;
    left: 0;
    transition: 450ms;
    transform: translate(0%, -50%);
  }
  span:nth-child(1){
    top:5%;
    width: 80%;
  }
  span:nth-child(2){
    top:50%;
  }
  span:nth-child(3){
    top:95%;
    width: 80%;
    left: auto;
    right: 0;

  }
  &:hover{
    span:nth-child(1){
      left: auto;
      width: 30%;
      right: 0;
      transform: rotate(45deg) translate(6px, 4px);
    }
    span:nth-child(2){
      top:50%;
      width: 70%;
      left: auto;
      right: 0;
    }
    span:nth-child(3){
      width: 30%;
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }
}

.burger_btn_container{
  display: none;
  @include tablet(){
    display: block;
  }
}

header.open .btn_burger, header.open .btn_burger:hover{
  span{
    left: 50%;
    transform: translate(-50%, 0);
  }
  span:nth-child(1){
    top:50%;
    width: 80%;
    transform: translateX(-50%) rotate(-45deg);
  }
  span:nth-child(2){
    top:50%;
    opacity: 0;
  }
  span:nth-child(3){
    top:50%;
    width: 80%;
    transform: translateX(-50%) rotate(45deg);
  }

}
.portfolio_link{
  display: block;
  @include tablet(){
    display: none;
  }
  a{
    border: 1px solid black;
    background-color: $color;
    padding: 18px;
    text-decoration: none;
    color: #232323;
    display: inline-block;
    transition: 250ms ease-in-out;
    position: relative;
    overflow: hidden;
    span{
      transition: 250ms;
    }
    &:after{
      content: "";
      position: absolute;
      background-color: white;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 0;
      transition: 250ms;
      transform: translateX(-50%);
    }
    &:hover{
      &:after{
        height: 100%;
        bottom: auto;
        top: 0;
      }
      span{
        color: $color;
        position: relative;
        z-index: 10;
      }
    }
  }
}

.nav_desktop{
  list-style: none;
  display: flex;
  @include tablet(){
    display: none;
  }
  li{

    a{
      padding: 15px;
      display: inline-block;
      color: #232323;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}
